.steps {
  margin: 0 auto;
}

.step {
  width: 100px;
  height: 100px;
}

.stepIcon {
  width: 38px;
  height: 38px;
}

.stepTitle {
  color: #FFD568;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
}

[display-type='2'] {
  .step {
    width: 40px;
    height: 40px;
  }

  .stepIcon {
    width: 16px;
    height: 16px;
  }

  .stepTitle {
    font-size: 14px;
  }
}

@media screen and (max-width: 768px) {

  .step {
    width: 60px;
    height: 60px;
  }

  .stepIcon {
    width: 24px;
    height: 24px;
  }
}
